<template lang="pug">
  form.pay-in-modal(@submit.prevent="onSubmit")
    .modal__header
      | {{ $t('payInBalance') }}

    .pay-in-modal__body
      //.pay-in-modal__warnings
      //  InfoMessage(v-if="user.verification_status === 'not_verified' || !isUserAdult() " type="warning")
      //    div(v-if="user.verification_status === 'not_verified'")
      //      | Для пополнения баланса необходимо&nbsp;
      //      a(href="/profile/personal-data" target="_blank") указать контактную информацию
      //
      //    div(v-else-if="!isUserAdult()")
      //      | Для пополнения баланса вы должны быть старше 16 лет

      .pay-in-modal__steps(v-if="currentStepIndex !== 10")
        Steps(:steps="stepTitles" :current-step-index="currentStepIndex")

      .pay-in-modal__section(v-if="currentStepIndex<2")
        .pay-in-modal__values
          .pay-in-modal__value
            .pay-in-modal__value-inner
              .pay-in-modal__value-title {{ $t('available') }}
              .pay-in-modal__value-money
                Currency(type="money" onlyRub) {{ gBalanceRounded }}
              //.pay-in-modal__value-money.pay-in-modal__value-money--verification
              //  | +
              //  Currency(type="money" onlyRub) {{ notVerifiedBalance }} {{ $t('onVerification') }}

          .pay-in-modal__value
            .pay-in-modal__value-inner
              .pay-in-modal__value-title {{ $t('payIn') }}
              .pay-in-modal__value-money
                Currency(type="money" ) {{ currentPrice }}

          .pay-in-modal__value(v-if="commission !== null && currentStepIndex > 0")
            .pay-in-modal__value-inner
              .pay-in-modal__value-title
                | {{ currentMethodId === 'credit-card' ? $t('willDebitedFromCard') : $t('willDebitedFromWallet') }}
                br
                | ({{ $t('payCommission', { commission: commission, additional: '' }) }}
                template(v-if="fixCommission") {{ $t('fixCommission', { commission: fixCommission }) }}
                | )
              .pay-in-modal__value-money
                Currency(type="money" real) {{ calcCommission(currentPrice).toFixed(2) }}

      .pay-in-modal__line(v-if="currentStepIndex<2")

      template(v-if="currentStepIndex === 0")
        //.pay-in-modal__title {{ $t('choosePayInSum') }}

        //.pay-in-modal__populars
        //  .pay-in-modal__popular(
        //    v-for="(item, index) in popularItems"
        //    :class="{ '--active': index === activeItemIndex }"
        //    @click="onPriceClick(index)"
        //  )
        //    .pay-in-modal__popular-badge(v-if="item.isPopular") {{ $t('mostPopular') }}
        //    Currency(type="money") {{ item.price }}

        .pay-in-modal__manual(:class="{ '--active': activeItemIndex < 0 }")
          span {{ $t('typeYourSum') }}
          NumberInput(v-model="manualPrice" currency="money" :increment="0.1" :min="1" @click.native="onPriceFocus" :max="maxPrice")

      template(v-if="currentStepIndex === 1")
        .pay-in-modal__title {{ $t('choosePayMethod') }}

        .pay-in-modal__method-variant(
          v-for="method in filteredMethods"
          :key="method.id"
          :class="[{'pay-in-modal__method-variant--active': currentMethodId === method.id}]"
          @click="onPayMethodClick(method.id)"
        )
          .pay-in-modal__method-variant-body(:class="[`--${method.id}`, {'pay-in-modal__method-variant-body--active': currentMethodId === method.id}]")
            .pay-in-modal__method-variant-checkbox(
              :class="{'pay-in-modal__method-variant-checkbox--active': currentMethodId === method.id}"
            )
            span.pay-in-modal__method-variant-title {{ $t(method.title) }}
            .pay-in-modal__method-variant-icons
              img(v-for="icon in method.icons" :key="icon.alt" :alt="icon.alt" :src="icon.src")
            #solid-payment-form-container(v-if="method.id === 'google-pay'")
            #google-button-container-id(v-if="method.id === 'google-pay'")
          .pay-in-modal__method-variant-footer(v-if="currentMethodId === 'bitcoin' && method.id === 'bitcoin'")
            .pay-in-modal__method-variant-warning
              img(:src="require('~/assets/img/icons/warning.svg')" alt="warning")
              | {{ $t('btcTransactionsConfirmationWarning') }}

          .pay-in-modal__method-variant-footer-usdt(v-if="currentMethodId === method.id  && method.provider === 'coinpayments' && cryptoWallet")
            .pay-in-modal__method-variant-info(v-if="cryptoWallet")
              span {{ $t('cryptoInfoPayIn', { wallet: cryptoWallet.address, sum: `${cryptoWallet.amount} ${method.methodName}` }) }}
              Button.pay-in-modal__method-variant-button(is-primary @click.native.prevent.stop="onCopyAddressClick") {{ $t('copyAddress') }}
            .pay-in-modal__method-variant-qr(v-if="cryptoWallet")
              img(:src="cryptoWallet.qrcode_url" alt="")

          .pay-in-modal__method-variant-footer(v-if="currentMethodId === 'credit-card' && method.id === 'credit-card' && isRussianBrowser")
            .pay-in-modal__method-variant-warning
              img(:src="require('~/assets/img/icons/warning.svg')" alt="warning")
              | {{ $t('russianCardWarning') }}

        .pay-in-modal__error-text(v-if="isError") {{errors[isError] || $t('errorMessageDefault') }}
        .pay-in-modal__error-text(v-if="currentMethodId === 'bitcoin' && currentPrice < minBtc") {{ $t('minBitcoinSum', {amount: minBtc})}}

        CheckboxInput(v-model="agreement").pay-in-modal__agreement
          span(v-html="$t('agreements')")

      template(v-if="currentStepIndex === 2")
        .pay-in-modal__status
          .pay-in-modal__wrapper(v-if="$route.query.payment_status === 'success' || googleSuccess")
            .pay-in-modal__container
              img.pay-in-modal__image(
                :src="require('~/assets/img/payment-systems/success.svg')"
                alt="success"
              )
              .pay-in-modal__status-title {{$t('payInSuccess')}}
            .pay-in-modal__transaction-info
              .pay-in-modal__transaction-info-text
                .pay-in-modal__small-text {{ $t('payInSuccessText') }}
                Currency(type="money" onlyRub) {{ numberFormat(price) }}

                .pay-in-modal__small-text {{ $t('payInSuccessNumber') }}
                Currency(type="money" onlyRub) {{ numberFormat(user.balance) }}

          .pay-in-modal__wrapper(v-if="paymentDeclined")
            .pay-in-modal__container
              img.pay-in-modal__image(
                :src="require('~/assets/img/payment-systems/decline.svg')"
                alt="decline"
              )
              .pay-in-modal__status-title {{$t('payInDecline')}}

          .pay-in-modal__wrapper(v-if="paymentInProgress")
            .pay-in-modal__container
              img.pay-in-modal__image(
                :src="require('~/assets/img/payment-systems/inprogress.svg')"
                alt="in progress"
              )
              .pay-in-modal__status-title {{$t('payInProgress')}}

          .pay-in-modal__wrapper(v-if="paymentCanceled")
            .pay-in-modal__container
              img.pay-in-modal__image(
                :src="require('~/assets/img/payment-systems/decline.svg')"
                alt="decline"
              )
              .pay-in-modal__status-title {{$t('payInCancel')}}

          .pay-in-modal__wrapper(v-if="googleError")
            .pay-in-modal__container
              img.pay-in-modal__image(
                :src="require('~/assets/img/payment-systems/decline.svg')"
                alt="decline"
              )
              .pay-in-modal__status-title {{ googleError }}

      .pay-in-modal__address(v-if="currentStepIndex !== 10")
        //- span {{ $t('ourCompanyAddress') }}
        |{{$t('ourCompanyAddress')}}: Solian Enterprises Limited. Registered number: HE 412444. Emmanouil road 44, KIRZIS CENTER, 3031 Limassol, Cyprus

    .modal__footer
      HelpButton.pay-in-modal__help-button(v-if="showHelpButton")
      Button.pay-in-modal__button(v-if="currentStepIndex > 0" is-secondary @click.prevent.stop="onBackClick") {{ $t('buttonBack') }}
      Button.pay-in-modal__button(v-if="!currentStepIndex" is-primary :is-disabled="isDisabled" type="submit") {{ buttonText }}
      GooglePayButton(v-else-if="isGooglePay && currentStepIndex !== 2" :isDisabled="isDisabled" :transactionInfo="transactionInfo" :amount="currentPrice" @onError="showGoogleError" @onSuccess="onGooglePaySuccess")
      Button.pay-in-modal__button.cauri-widget-button(v-else v-show="currentStepIndex !== 2 && currentStepIndex !== 10" :is-disabled="isDisabled" is-primary @click="onPayClick") {{ $t('goToPay', { amount: calcCommission(currentPrice).toFixed(2)  }) }}
</template>

<script>
import qs from 'qs'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { copyText, isUserAdult as isUserAdultUtil } from '~/utils/utils'
import FormInput from '~/components/FormInput/FormInput'
import NumberInput from '~/components/NumberInput/NumberInput'
import Steps from '~/components/Steps/Steps'
import CheckboxInput from '~/components/CheckboxInput/CheckboxInput'
import PaymentService from '~/services/PaymentService'
import GooglePayButton from '~/components/GooglePayButton'
import HelpButton from '~/components/HelpButton'
import { postToUrl } from '~/utils/post-to-url'

const PUBLIC_KEY = 'Z_gOjpKCZbJ_7msLdoxDS8sMQ7vMHVn3'
const DEV_PUBLIC_KEY = 'f83434c508b00d5eb88064981dca7a4e'

export default {
  name: 'PayInModal',

  components: {
    CheckboxInput,
    Steps,
    NumberInput,
    FormInput,
    GooglePayButton,
    HelpButton
  },

  data () {
    return {
      projectKey:
        this.$config.PROJECT_ENV === 'production' ? PUBLIC_KEY : DEV_PUBLIC_KEY,

      params: {
        currency: 'EUR',
        description: this.$t('vvvPayIn')
      },

      price: 21,
      maxPrice: 500,
      manualPrice: '21',
      isButtonReady: true,
      googleError: null,
      googleSuccess: null,

      picked: 'EUR',

      popularItems: [
        { price: 5 },
        { price: 20, isPopular: true },
        { price: 50 },
        { price: 100 }
      ],

      methods: [],

      currentMethodId: 'credit-card',
      activeItemIndex: 1,
      currentStepIndex: 0,
      steps: ['amount', 'method', 'result'],
      agreement: true,
      paymentInProgress: false,

      minBtc: 100,
      btcRate: 100000000,
      usdtWallet: '',
      cryptoWallet: undefined,

      applePayOk: false,

      isError: null,
      errors: {
        16: this.$t('emailNotConfirmed'),
        20005: this.$t('wrongEmail'),
        20004: this.$t('wrongWallet'),
        20003: this.$t('emptyCardHolder'),
        20002: this.$t('emptyCardNumber'),
        20001: this.$t('wrongExpirationDate'),
        20006: this.$t('errorCode20006')
      }
    }
  },

  computed: {
    ...mapState('user', ['user', 'verification', 'canBeWithdrawn', 'gBalance']),
    ...mapGetters('user', ['isAdmin', 'minWithdrawalLimit']),

    isRussianBrowser () {
      const browserLang = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage

      return browserLang === 'ru'
    },

    commission () {
      return this.currentMethod?.commission
    },

    fixCommission () {
      return this.currentMethod?.fixCommission
    },

    gBalanceRounded () {
      return (this.gBalance / 100).toFixed(2).replace('.00', '')
    },

    paymentDeclined () {
      return this.$route.query.payment_status === 'decline'
    },

    paymentCanceled () {
      return this.$route.query.payment_status === 'cancel'
    },

    showHelpButton () {
      return (
        this.isError ||
        this.googleError ||
        this.paymentDeclined ||
        this.paymentCanceled
      )
    },

    buttonText () {
      let text = 'continue'
      switch (this.currentStepIndex) {
        case 0:
          text = 'choosePaymentMethod'
          break
        case 1:
          text = 'goToPay'
          break
        case this.steps.length - 1:
          text = 'buttonFinish'
      }
      return this.$t(text)
    },

    showGooglePay () {
      if (this.currentStepIndex === 2) { return false }
      // if (this.$config.PROJECT_ENV === 'production') {
      //   return UsersService.isUserEmployee(this.user)
      // } else {
      return true
      // }
    },

    stepTitles () {
      return [
        this.$t('chooseSum'),
        this.$t('payMethod'),
        this.$t('stepConfirm')
      ]
    },

    filteredMethods () {
      return this.methods
        .filter(method => method.id !== 'apple-pay' || this.applePayOk)
        .filter(method => !method.adminOnly || this.isAdmin)
        .filter(method => method.id !== 'credit-card-paymentlnk' || this.user?.id === 3088 || this.user?.id === 832176)
        .filter(method => !method.ruOnly || this.$i18n.locale === 'ru')
        .filter(method => !method.euOnly || this.$i18n.locale !== 'ru')
    },

    currentMethod () {
      return this.filteredMethods.find(m => m.id === this.currentMethodId)
    },

    transactionInfo () {
      return {
        totalPriceStatus: 'FINAL',
        totalPrice: `${this.currentPrice}.00`,
        currencyCode: this.picked,
        countryCode: 'RU'
      }
    },

    isDisabled () {
      if (!this.picked) {
        return true
      }

      if (this.manualPrice < 1) {
        return true
      }

      if (this.manualPrice > this.maxPrice) {
        return true
      }

      if (
        this.currentPrice < this.minBtc &&
        this.currentMethodId === 'bitcoin'
      ) {
        return true
      }

      // if (
      //   this.user.verification_status === 'not_verified' ||
      //   !this.isUserAdult()
      // ) {
      //   return true
      // }

      if (
        this.currentStepIndex === 0 &&
        this.activeItemIndex === -1 &&
        this.manualPrice <= 0
      ) {
        return true
      }

      if (this.currentStepIndex === 1 && this.currentMethodId === 'wallet') {
        return true
      }

      if (this.currentStepIndex === 1 && !this.agreement) {
        return true
      }

      return false
    },

    currentPrice () {
      if (this.activeItemIndex >= 0) {
        return this.price
      } else {
        return this.manualPrice
      }
    },

    isGooglePay () {
      return this.currentMethodId === 'google-pay'
    },

    notVerifiedBalance () {
      return (this.user.balance - (this.canBeWithdrawn / 100)).toFixed(2).replace('.00', '')
    }

    // googlePayOptions() {
    //   return {
    //     environment: 'TEST',
    //     buttonColor: 'white',
    //     allowedCardNetworks: [
    //       'AMEX',
    //       'DISCOVER',
    //       'INTERAC',
    //       'JCB',
    //       'MASTERCARD',
    //       'VISA',
    //     ],
    //     allowedCardAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    //     merchantInfo: {
    //       merchantName: 'Example Merchant',
    //       merchantId: '0123456789',
    //     },
    //     transactionInfo: {
    //       totalPriceStatus: 'FINAL',
    //       totalPrice: '1.00',
    //       currencyCode: 'USD',
    //       countryCode: 'US',
    //     },
    //     tokenizationSpecification: {
    //       type: 'PAYMENT_GATEWAY',
    //       parameters: {
    //         gateway: 'example',
    //         gatewayMerchantId: 'exampleGatewayMerchantId',
    //       },
    //     },
    //   }
    // },
  },

  watch: {
    currentStepIndex () {
      this.$emit('recalculatePosition')
    },

    manualPrice () {
      if (this.manualPrice?.split('.')?.[1]?.length > 2) {
        this.manualPrice = (+this.manualPrice).toFixed(2).replace('.00', '')
      }
    }
  },

  beforeMount () {
    // this.$gtag.event('pay_in_process', { step: 1 })
  },

  mounted () {
    this.fetchVerification()
    this.fetchCanBeWithdrawn()
    this.getMinBtc()
    this.checkApplePayIsOk()
    this.methods = PaymentService.getPaymentMethods('payIn', this.showGooglePay)

    if (
      Object.keys(this.$store.getters['global/getPlatformRemoteFeatures'])
        .length > 0
    ) {
      this.popularItems =
        this.$store.getters['global/getPlatformRemoteFeatures'].donation
      this.price = this.$store.getters['global/getPlatformRemoteFeatures'].price
    }

    if (this.$route.query.payment_status || this.$route.query.transaction) {
      this.currentStepIndex = 2
      PaymentService.getTransactionInfo(
        this.$axios,
        this.$route.query.transaction
      ).then((response) => {
        const { data } = response.data
        this.price = data.payment / 100
        if (data.is_done && !data.is_error) {
          this.googleSuccess = true
        }
        this.fetchUser()
      })
    }

    // create script
    const scriptElement = document.createElement('script')
    scriptElement.setAttribute(
      'src',
      'https://cdn.solidgate.com/js/solid-form.js'
    )
    this.$el.firstChild.appendChild(scriptElement)
  },

  beforeDestroy () {
    this.$router.replace({ query: null })
  },

  methods: {
    ...mapActions('modal', ['showModal']),
    ...mapActions('user', ['fetchUser', 'fetchVerification', 'fetchCanBeWithdrawn']),
    ...mapMutations('modal', ['toggle']),

    showGoogleError (err) {
      this.googleError = err
      this.currentStepIndex = 2
    },

    checkApplePayIsOk () {
      // if (!this.isAdmin) {
      //   return
      // }

      if (!window.ApplePaySession) {
        return
      }

      this.applePayOk = window.ApplePaySession.canMakePayments()
    },

    formatMoney (value) {
      return Math.floor(value * 100) / 100
    },

    getLink (link, ext = 'pdf') {
      ext = '.' + ext

      return link.replace(
        ext,
        this.$i18n.locale === 'ru' ? `_ru${ext}` : `_en${ext}`
      )
    },

    isUserAdult () {
      return isUserAdultUtil(this.verification, this.$dateFns)
    },

    onBackClick () {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex -= 1
        this.isButtonReady = false
        this.currentMethodId = 'credit-card'
      }
    },

    calcCommission (amount) {
      if (!amount) { return 0 }

      const result = parseFloat(amount) + (amount / 100) * this.commission

      if (this.fixCommission) {
        return result + this.fixCommission
      }

      return Math.ceil(result * 100) / 100
    },

    getMinBtc () {
      PaymentService.getBitcoinRate(this.$axios).then((btcRate) => {
        if (btcRate) {
          this.btcRate = btcRate
          this.minBtc = Math.ceil(btcRate * 0.001)
        }
      })
    },

    numberFormat (n) {
      return n
        .toString()
        .split('')
        .reverse()
        .join('')
        .match(/.{1,3}/g)
        .join(' ')
        .split('')
        .reverse()
        .join('')
    },

    onPayClick () {
      const method = this.currentMethod
      this.$nuxt.$loading.start()

      if (this.currentMethodId === 'usdt') {
        PaymentService.payWithUsdt(this.$axios, this.currentPrice)
          .then(() => {
            this.currentStepIndex++
            this.googleSuccess = true
            this.$nuxt.$loading.finish()
          })
          .catch((err) => {
            this.isError = err.response?.data?.code || true
            this.$nuxt.$loading.finish()
          })
      } else if (method.provider === 'coinpayments') {
        // TODO: check status
        this.currentStepIndex++
        this.paymentInProgress = true
        this.$nuxt.$loading.finish()
      } else if (method.provider === 'enot') {
        PaymentService.payWithEnot(this.$axios, this.currentPrice)
          .then((response) => {
            const { paymentID, signature, converted } = response.data?.data
            this.$nuxt.$loading.finish()
            window.open(PaymentService.getEnotLink(converted, paymentID, signature, method.id === 'qiwi'), '_blank')
          })
          .catch((err) => {
            this.isError = err.response?.data?.code || true
            this.$nuxt.$loading.finish()
          })
      } else if (method.provider === 'contactpay') {
        PaymentService.payWithContactpay(this.$axios, this.currentPrice, method.id === 'ru-card' ? null : this.verification?.phone, this.user.id === 189432)
          .then((response) => {
            if (method.id === 'ru-sbp') {
              location.href = response.data?.data?.url
            } else if (response.data?.data?.method === 'GET') {
              location.href = `${response.data?.data?.url}?${qs.stringify(response.data?.data?.data)}`
            } else {
              try {
                postToUrl(response.data?.data?.url, response.data?.data?.data)
              } catch (err) {
                console.log(err)
              }
            }
            this.$nuxt.$loading.finish()
          })
          .catch((err) => {
            this.isError = err.response?.data?.code || true
          })
      } else if (method.provider === 'paymentlnk' && method.id?.includes('sbp')) {
        PaymentService.payWithPaymentlnkSBP(this.$axios, this.currentPrice, location.href)
          .then((response) => {
            try {
              postToUrl(response.data?.data?.url, response.data?.data?.data)
            } catch (err) {
              console.log(err)
            }
            this.$nuxt.$loading.finish()
          })
          .catch((err) => {
            this.$nuxt.$loading.finish()
            this.isError = err.response?.data?.code || true
          })
      } else if (method.provider === 'paymentlnk' && method.id?.includes('card')) {
        PaymentService.payWithPaymentlnk(this.$axios, this.currentPrice, location.href)
          .then((response) => {
            try {
              postToUrl(response.data?.data?.url, response.data?.data?.data)
            } catch (err) {
              console.log(err)
            }
            this.$nuxt.$loading.finish()
          })
          .catch((err) => {
            this.$nuxt.$loading.finish()
            this.isError = err.response?.data?.code || true
          })
      } else if (method.provider === 'pay2play') {
        PaymentService.payWithPay2Play(this.$axios, this.currentPrice)
          .then((response) => {
            this.$nuxt.$loading.finish()
            if (response.data?.data) {
              location.href = response.data.data.payment_url
            }
          })
          .catch((err) => {
            console.log(err)
            this.isError = true
            this.$nuxt.$loading.finish()
          })
      } else if (this.currentMethodId === 'apple-pay') {
        const request = {
          countryCode: 'CY',
          currencyCode: 'EUR',
          supportedNetworks: ['visa', 'masterCard'],
          merchantCapabilities: ['supports3DS'],
          total: { label: 'VVV', amount: Number(this.currentPrice) }
        }
        const session = new window.ApplePaySession(3, request)
        session.onvalidatemerchant = (event) => {
          PaymentService.getApplePaySession(this.$axios, event.validationURL)
            .then((response) => {
              const sessionData = response.data?.data
              session.completeMerchantValidation(sessionData)
              this.$nuxt.$loading.finish()
            })
            .catch((err) => {
              session.abort()
              this.isError = err.response?.data?.code || true
              this.$nuxt.$loading.finish()
            })
        }
        session.onpaymentauthorized = (event) => {
          const paymentData = event.payment
          PaymentService.getPayInLink(this.$axios, {
            amount: this.currentPrice,
            currency: this.picked,
            method: method.methodName,
            return_url: `${location.protocol}//${location.host}${location.pathname}`,
            apple_pay_data: btoa(JSON.stringify(paymentData))
          })
            .then(() => {
              session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
              this.currentStepIndex++
              this.paymentInProgress = true
              this.$nuxt.$loading.finish()
            })
            .catch((err) => {
              session.completePayment(window.ApplePaySession.STATUS_FAILURE)
              this.isError = err.response?.data?.code || true
              this.$nuxt.$loading.finish()
            })
        }
        session.begin()
      } else if (this.currentMethodId === 'alfa') {
        location.href = 'https://alfa-wallet.com'
        this.$nuxt.$loading.finish()
      } else {
        PaymentService.getPayInLink(this.$axios, {
          amount: this.currentPrice,
          currency: this.picked,
          method: method.methodName,
          return_url: `${location.protocol}//${location.host}${location.pathname}`
        })
          .then((response) => {
            this.$nuxt.$loading.finish()
            window.location.replace(response.data.data)
          })
          .catch((err) => {
            this.isError = err.response?.data?.code || true
            this.$nuxt.$loading.finish()
          })
      }
    },

    onPayMethodClick (methodId) {
      if (this.currentMethodId === methodId) {
        return
      }

      const method = PaymentService.getMethod(methodId)

      this.currentMethodId = methodId

      if (methodId === 'usdt') {
        PaymentService.getUsdtWallet(this.$axios).then((response) => {
          this.usdtWallet = response.data?.data
        })
      } else if (method.provider === 'coinpayments') {
        this.cryptoWallet = undefined
        this.$nuxt.$loading.start?.()
        PaymentService.getCoinPaymentsData(this.$axios, {
          amount: this.currentPrice,
          currency: method.methodName
        })
          .then((response) => {
            this.cryptoWallet = response.data?.data
            this.$nuxt.$loading.finish?.()
          })
          .catch((err) => {
            this.isError = err.response?.data?.code || true
            this.$nuxt.$loading.finish?.()
          })
      }

      // this.loadPayment()
    },

    // async onGooglePayGetConfig() {
    //   const { data } = await PaymentService.getGooglePayConfig(this.$axios)
    //   console.log(data)
    // },

    //     async onGooglePayStartPayment(amount) {
    //       // request to our backend
    //       const { data } = await PaymentService.getSolidGateSignature(this.$axios, {
    //         amount,
    //         platform: 'WEB',
    //       })

    //       const formRequestData = {
    //         merchantData: data.data,
    //         formParams: {},
    //         googlePayButtonParams: {
    //           containerId: 'google-button-container-id',
    //           color: 'white',
    //           type: 'plain',
    //         },
    //         styles: {
    //           submit_button: {
    //             'background-color': 'red',
    //             'font-size': '16px',
    //             'font-weight': 'bold',
    //             ':hover': {
    //               'background-color': 'green',
    //             },
    //             form_body: {
    //               'font-family': 'DM Sans',
    //             },
    //           },
    //         },
    //       }

    //       console.log(formRequestData)

    //       const form = PaymentFormSdk.init(formRequestData) // eslint-disable-line no-undef

    //       /*  setTimeout(() => {
    //         document.getElementsByClassName('gpay-button')[0].click()
    //       }, 1000)
    //  */
    //       form.on('event_name', (e) => {
    //         console.log(e)
    //       })
    //     },

    /*     createScript(params) {
      const scriptEl = document.createElement('script')

      scriptEl.setAttribute(
        'src',
        'https://checkout.cauri.com/widget-v1/widget.js'
      )
      scriptEl.className = 'cauri-widget'
      scriptEl.setAttribute('async', 'async')
      scriptEl.setAttribute('data-project', this.projectKey)
      scriptEl.setAttribute('data-currency', this.params.currency)
      scriptEl.setAttribute('data-description', this.params.description)
      scriptEl.setAttribute('data-user', this.user?.id)
      scriptEl.setAttribute('data-display-name', this.user?.full_name || '')
      scriptEl.setAttribute('data-email', this.user?.email || '')
      scriptEl.setAttribute('data-config-create-button', 'false')
      // scriptEl.setAttribute('data-additional-methods', '0')
      scriptEl.setAttribute('data-locale', 'ru')
      scriptEl.setAttribute('data-price', params.price)
      scriptEl.setAttribute('data-signature', params.signature)

      scriptEl.onload = () => {
        window.Cauri.Widget.Button.load()
        this.isButtonReady = true
        this.$nuxt.$loading.finish()

        window.Cauri.Widget.Iframe.on('open', () => {
          this.toggle(false)
          this.$nuxt.$loading.finish()
        })
      }

      this.$el.firstChild.appendChild(scriptEl)
    }, */

    onPriceFocus () {
      this.activeItemIndex = -1
    },

    onPriceClick (index) {
      this.price = this.popularItems[index].price
      this.activeItemIndex = index
    },

    onCopyAddressClick () {
      copyText(this.cryptoWallet.address)
    },

    onSubmit () {
      if (this.isDisabled) {
        return
      }

      if (this.currentStepIndex === 0) {
        this.currentStepIndex++
        this.onPayMethodClick('credit-card')

        // this.$gtag.event('pay_in_process', { step: 2 })
      }
    },

    onGooglePaySuccess (price) {
      this.googleSuccess = true
      this.price = price
      this.currentStepIndex = 2
    }

    /*     onCauriButtonClick() {
      this.$nuxt.$loading.start()

          this.$gtag.event('pay_in_process', { step: 3 })
    }, */
  }
}
</script>

<style lang="scss">
.modal.modal--payinmodal {
  max-width: 736px;
  padding-bottom: 0;

  .modal__footer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    flex-shrink: 0;

    button {
      margin-top: 4px;
    }
  }
}

.pay-in-modal {
  &__about-limit {
    color: $blue-grey10;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;

    a {
      color: $primary60;
    }
  }

  &__warning {
    &-title {
      color: $white;
      margin-bottom: 8px;
      font-weight: bold;
    }

    &-text {
      color: #cfd8dc;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }

    &-radio {
      display: flex;
    }

    &-container {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 12px;
      margin-right: 12px;
      width: 100%;
      cursor: pointer;
      color: $white;
      background: #232f3f;
      border-radius: 8px;
      padding: 20px 16px 20px 54px;
      font-size: 16px;
      user-select: none;

      .pay-in-modal__warning-checkmark {
        position: absolute;
        top: 21px;
        left: 16px;
        height: 20px;
        width: 20px;
        border: 2px solid #556877;
        border-radius: 50%;

        &::after {
          content: "";
          position: absolute;
          display: none;
          top: 5px;
          left: 4px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: white;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .pay-in-modal__warning-checkmark {
          border-color: transparent;
          background-color: #2196f3;

          &::after {
            display: block;
          }
        }
      }
    }

    &-currency {
      margin-left: auto;

      path {
        fill: $green50;
      }
    }

    &-banner {
      display: flex;
      align-items: center;
      color: #ffb300;
      background-color: rgb(255 179 0 / 20%);
      border-radius: 8px;
      padding: 20px;
      margin-top: 24px;

      &-icon {
        margin-right: 16px;
      }
    }
  }

  &__help-button {
    margin-right: 12px;
  }

  &__warnings > .info-message {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 32px;
    }

    a {
      color: #fff;
      text-decoration: underline;
    }

    div + div {
      margin-top: 16px;
    }
  }

  &__address {
    @include text-display120;

    margin-top: 32px;
    color: #90a4ae;
    font-size: 14px;

    > span {
      @include caption-small;

      display: block;
      color: $blue-grey20;
      margin-bottom: 8px;
    }
  }

  &__agreement {
    @include text-display120;

    color: $blue-grey20;

    a {
      color: $primary60;
    }

    .checkbox-input__label {
      align-items: flex-start;
    }

    .checkbox-input__text {
      min-width: unset;
      width: auto;
    }
  }

  &__method-variant {
    display: grid;
    grid-template-columns: 1fr;
    margin: 16px 0 12px;
    border-radius: 8px;

    &--active {
      background: rgba($primary60, 0.2);
    }

    &-body {
      display: grid;
      grid-template-columns: 20px 1fr;
      background: $blue-grey60;
      border-radius: 8px;
      width: 100%;
      align-items: center;
      padding: 24px 20px;
      cursor: pointer;
      flex-wrap: wrap;

      @media (min-width: $breakpointTablet) {
        display: grid;
        grid-template-columns: 20px 1fr max-content;
      }

      &--active {
        background: linear-gradient(90.63deg, #1565c0 0%, #1e88e5 100%);
      }
    }

    &-button {
      margin-top: 8px;
    }

    &-footer {
      padding: 10px 30px 12px;
    }

    &-footer-usdt {
      padding: 10px 10px 12px;
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-gap: 12px;
      max-width: 100%;
    }

    &-warning {
      display: grid;
      grid-template-columns: 21px 1fr;
      grid-gap: 9px;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $primary05;

      & img {
        width: 21px;

        path {
          fill: $white;
        }

        g {
          fill: $white;
        }
      }
    }

    &-info {
      max-width: 100%;
      overflow-x: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $primary05;
    }

    &-title {
      @include heading6;

      padding: 0 12px;
      color: #fff;
    }

    &-checkbox {
      border: 2px solid #fff;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: all 0.2s $easing;
      position: relative;

      &--active {
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 3px;
          right: 0;
          top: 3px;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background: #fff;
          transition: transform 0.2s $easing;
        }
      }
    }

    &-icons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 16px;
      grid-column-start: 1;
      grid-column-end: 3;

      img {
        height: 18px;
        margin: 0 7px;

        &:last-child {
          margin-right: 0;
        }
      }

      @media (min-width: $breakpointTablet) {
        grid-column-start: auto;
        grid-column-end: auto;
        margin-top: 0;
      }
    }
  }

  &__card-warning {
    @include caption-small;

    text-align: right;
    margin-bottom: 23px;
    color: $orange60;
  }

  &__button + &__button {
    margin-left: 16px;
  }

  &__methods {
    display: flex;

    &-list {
      width: 276px;
      border-right: 1px solid $blue-grey30;
      padding-right: 24px;
    }

    &-content {
      padding-left: 24px;
      width: 100%;

      .form-input.--error {
        padding-bottom: 24px;
      }
    }

    @media (max-width: $breakpointMobile) {
      flex-direction: column;

      &-list {
        width: 100%;
        border-bottom: 1px solid $blue-grey30;
        border-right: none;
        display: flex;
      }

      &-content {
        padding-left: 0;
        width: 100%;
        padding-top: 24px;
      }
    }
  }

  &__method {
    margin-bottom: 24px;
    display: flex;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &-icon {
      margin-right: 16px;
      min-width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $blue-grey60;
      transition: background 0.2s $easing;

      svg path,
      svg rect {
        fill: $blue-grey30;
        transition: fill 0.2s $easing;
      }

      @media (max-width: $breakpointMobile) {
        max-width: 48px;
        margin-bottom: 16px;
        margin-right: 0;
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      @include text-display100;

      color: $blue-grey20;
      transition: color 0.2s $easing;

      @media (max-width: $breakpointMobile) {
        white-space: normal;
        text-align: center;
      }
    }

    &-caption {
      @include text-small;

      color: $blue-grey30;
      margin-top: 4px;
      transition: color 0.2s $easing;

      @media (max-width: $breakpointMobile) {
        display: none;
      }
    }

    &:hover,
    &.--active {
      .pay-in-modal {
        &__method {
          &-title {
            color: $white;
          }

          &-caption {
            color: $blue-grey10;
          }
        }
      }
    }

    &.--active.--credit-card .pay-in-modal__method-icon {
      background: $primary60-20;

      svg path,
      svg rect {
        fill: $primary60;
      }
    }

    &.--active.--wallet .pay-in-modal__method-icon {
      background: $pink60-20;

      svg path,
      svg rect {
        fill: $pink60;
      }
    }

    &.--active.--phone .pay-in-modal__method-icon {
      background: $yellow60-20;

      svg path,
      svg rect {
        fill: $yellow60;
      }
    }

    @media (max-width: $breakpointMobile) {
      flex-direction: column;
      align-items: center;
      margin-right: 16px;
      max-width: 90px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__error-text {
    color: red;
    font-weight: bold;
    padding: 5px 0 30px;
  }

  &__wallet {
    background: $blue-grey50;
    max-height: 56px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.--active {
      max-height: initial;
      cursor: auto;
    }

    &-top {
      display: flex;
      align-items: center;
      height: 56px;
      padding-left: 18px;
    }

    &-form {
      padding: 16px;
      border-top: 1px solid $blue-grey30;

      .form-input + .form-input {
        margin-top: 16px;
      }
    }

    &-title {
      @include heading7;

      width: 100%;
      margin-left: 10px;
      color: #fff;
    }

    &-icon {
      min-width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      border-left: 1px solid $blue-grey30;

      img {
        .--yandex & {
          width: 68px;
        }

        .--qiwi & {
          width: 58px;
        }
      }
    }

    &-checkbox {
      border: 2px solid $blue-grey30;
      min-width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: all 0.2s $easing;
      position: relative;

      .--active & {
        border: 2px solid $primary60;
      }

      &::before {
        transform: scale(0, 0);
        content: "";
        position: absolute;
        bottom: 0;
        left: 3px;
        right: 0;
        top: 3px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: $primary60;
        transition: transform 0.2s $easing;
      }

      .--active &::before {
        transform: scale(1, 1);
      }
    }
  }

  &__card {
    padding: 32px 24px 40px;
    background: $blue-grey50;
    border-radius: 8px;
    width: 100%;

    &-icons {
      margin-bottom: 32px;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      img {
        height: 16px;
      }
    }

    &-caption {
      @include text-display100;

      color: $blue-grey05;
    }

    &-row + &-row {
      margin-top: 16px;
    }
  }

  &__manual {
    display: flex;
    align-items: center;
    margin-top: 8px;

    > span {
      @include heading5;

      color: #fff;
      margin-right: 16px;
    }

    .number-input {
      svg path {
        fill: #fff;
      }

      &__label {
        transition: background 0.2s $easing;
      }
    }

    &.--active .number-input:not(.--error) .number-input__label {
      background: $primary60;
    }
  }

  &__populars {
    display: flex;

    @media (max-width: $breakpointMobile) {
      flex-wrap: wrap;
    }
  }

  &__popular {
    margin-right: 16px;
    background: $blue-grey50;
    border-radius: 8px;
    height: 72px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    position: relative;
    transition: background 0.2s $easing;
    cursor: pointer;
    margin-bottom: 16px;

    &:hover {
      background: $blue-grey40;
    }

    @media (max-width: $breakpointMobile) {
      width: calc(50% - 8px);

      &:nth-child(2) {
        margin-right: 0;
      }
    }

    &.--active {
      background: $primary60;
    }

    &-badge {
      @include text-small;

      height: 24px;
      background: $blue-grey30;
      width: 100%;
      position: absolute;
      top: -12px;
      color: #fff;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 8px 8px 0 0;
      text-align: center;
      transition: background 0.2s $easing;

      .--active & {
        background: $primary50;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .currency {
      @include heading4;

      color: #fff;

      svg path {
        fill: #fff;
      }
    }
  }

  &__title {
    @include heading5;

    color: #fff;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  &__values {
    @include text-small;

    background: $blue-grey60;
    border-radius: 8px;
    color: $blue-grey10;
    display: flex;
    min-height: 81px;
    margin: 24px 0;
    padding: 8px 0;
  }

  &__line {
    border-bottom: 1px solid $blue-grey30;
    margin: 0 -24px;

    @media (max-width: $breakpointMobile) {
      margin: 0 -16px;
    }
  }

  &__value {
    width: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;

    .currency {
      @include heading4;
    }

    &::after {
      content: "";
      position: absolute;
      height: 50%;
      width: 1px;
      background: $blue-grey30;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child::after {
      content: none;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin: 0 auto;
      justify-content: space-between;
      text-align: center;
      height: 100%;
    }

    &-money {
      @include heading4;

      margin-top: 10px;
      color: $white;

      &--verification {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 18px;
        color: $blue-grey05;

        .currency__text {
          font-size: 16px;
          line-height: 18px;
        }

        .currency__icon {
          width: 16px;
          height: 16px;
        }
      }
    }

    &-title {
      height: 100%;
      display: flex;
      align-items: center;

      @media (max-width: $breakpointMobile) {
        padding: 0 4px;
      }
    }
  }

  &__body {
    padding: 40px 24px 24px 0;
    margin-right: -24px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 180px);
    scrollbar-width: thin;
    scrollbar-color: #455566 #232f3f;

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 20px;
    }

    &::-webkit-scrollbar-track {
      background: #232f3f;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #455566;
      border-radius: 2px;
      border: 6px solid #232f3f;
    }
  }

  &__status {
    margin-top: 40px;
    padding: 20px;
    color: $white;
    border-radius: 8px;
    background-color: $blue-grey60;

    &-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__image {
    margin-right: 21px;
  }

  &__transaction-info {
    padding-left: 70px;

    &-text {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-row-gap: 7px;
      grid-column-gap: 12px;
    }
  }

  &__small-text {
    color: $grey05;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

#solid-payment-form-container,
#google-button-container-id {
  // display: none;
}
</style>
