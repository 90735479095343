import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _32ad42f4 = () => interopDefault(import('../pages/become-advertiser.vue' /* webpackChunkName: "pages/become-advertiser" */))
const _442c0fa2 = () => interopDefault(import('../pages/bonus.vue' /* webpackChunkName: "pages/bonus" */))
const _580d0368 = () => interopDefault(import('../pages/earn/index.vue' /* webpackChunkName: "pages/earn/index" */))
const _7bad84c7 = () => interopDefault(import('../pages/games/index.vue' /* webpackChunkName: "pages/games/index" */))
const _29b33bc0 = () => interopDefault(import('../pages/leaderboard.vue' /* webpackChunkName: "pages/leaderboard" */))
const _f067ac28 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _46f96b91 = () => interopDefault(import('../pages/marketplace/index.vue' /* webpackChunkName: "pages/marketplace/index" */))
const _716de6b7 = () => interopDefault(import('../pages/missions/index.vue' /* webpackChunkName: "pages/missions/index" */))
const _02d3462a = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _2cb7d3b8 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _7ed39528 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _1093acec = () => interopDefault(import('../pages/profile/account.vue' /* webpackChunkName: "pages/profile/account" */))
const _3ed44f97 = () => interopDefault(import('../pages/profile/achievements.vue' /* webpackChunkName: "pages/profile/achievements" */))
const _b389c28e = () => interopDefault(import('../pages/profile/balance.vue' /* webpackChunkName: "pages/profile/balance" */))
const _7386974c = () => interopDefault(import('../pages/profile/events.vue' /* webpackChunkName: "pages/profile/events" */))
const _6be555c6 = () => interopDefault(import('../pages/profile/favourites.vue' /* webpackChunkName: "pages/profile/favourites" */))
const _456a3e04 = () => interopDefault(import('../pages/profile/game-accounts.vue' /* webpackChunkName: "pages/profile/game-accounts" */))
const _0a898773 = () => interopDefault(import('../pages/profile/goods.vue' /* webpackChunkName: "pages/profile/goods" */))
const _a75f3320 = () => interopDefault(import('../pages/profile/goods-moderation.vue' /* webpackChunkName: "pages/profile/goods-moderation" */))
const _fd7e5cc6 = () => interopDefault(import('../pages/profile/lichess-connect.vue' /* webpackChunkName: "pages/profile/lichess-connect" */))
const _05f91705 = () => interopDefault(import('../pages/profile/notifications.vue' /* webpackChunkName: "pages/profile/notifications" */))
const _65f05318 = () => interopDefault(import('../pages/profile/orders.vue' /* webpackChunkName: "pages/profile/orders" */))
const _5b329e04 = () => interopDefault(import('../pages/profile/quests.vue' /* webpackChunkName: "pages/profile/quests" */))
const _20f5c120 = () => interopDefault(import('../pages/profile/ref.vue' /* webpackChunkName: "pages/profile/ref" */))
const _9b1f23ae = () => interopDefault(import('../pages/profile/sales.vue' /* webpackChunkName: "pages/profile/sales" */))
const _1e63b19a = () => interopDefault(import('../pages/profile/security.vue' /* webpackChunkName: "pages/profile/security" */))
const _3bdae83c = () => interopDefault(import('../pages/profile/stats.vue' /* webpackChunkName: "pages/profile/stats" */))
const _bb60a5e0 = () => interopDefault(import('../pages/profile/subscription.vue' /* webpackChunkName: "pages/profile/subscription" */))
const _66e6f4d2 = () => interopDefault(import('../pages/reset.vue' /* webpackChunkName: "pages/reset" */))
const _dc13ba32 = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _b822480e = () => interopDefault(import('../pages/subscriptions.vue' /* webpackChunkName: "pages/subscriptions" */))
const _ccad8ab6 = () => interopDefault(import('../pages/terms_and_conditions.vue' /* webpackChunkName: "pages/terms_and_conditions" */))
const _3f2e4d26 = () => interopDefault(import('../pages/tournaments.vue' /* webpackChunkName: "pages/tournaments" */))
const _2aad779b = () => interopDefault(import('../pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _c21d00f4 = () => interopDefault(import('../pages/verify.vue' /* webpackChunkName: "pages/verify" */))
const _63123e30 = () => interopDefault(import('../pages/desktop/download.vue' /* webpackChunkName: "pages/desktop/download" */))
const _9bfa61ca = () => interopDefault(import('../pages/desktop/howto.vue' /* webpackChunkName: "pages/desktop/howto" */))
const _04c8a412 = () => interopDefault(import('../pages/earn/referral.vue' /* webpackChunkName: "pages/earn/referral" */))
const _06176a2e = () => interopDefault(import('../pages/earn/stories.vue' /* webpackChunkName: "pages/earn/stories" */))
const _7c66af16 = () => interopDefault(import('../pages/earn/verification.vue' /* webpackChunkName: "pages/earn/verification" */))
const _150495f9 = () => interopDefault(import('../pages/info/about.vue' /* webpackChunkName: "pages/info/about" */))
const _49cfad79 = () => interopDefault(import('../pages/info/age-restrictions.vue' /* webpackChunkName: "pages/info/age-restrictions" */))
const _3171d297 = () => interopDefault(import('../pages/info/contacts.vue' /* webpackChunkName: "pages/info/contacts" */))
const _4bede427 = () => interopDefault(import('../pages/info/cookie-policy.vue' /* webpackChunkName: "pages/info/cookie-policy" */))
const _d82641c6 = () => interopDefault(import('../pages/info/g-cash.vue' /* webpackChunkName: "pages/info/g-cash" */))
const _061956d8 = () => interopDefault(import('../pages/info/kyc-aml.vue' /* webpackChunkName: "pages/info/kyc-aml" */))
const _1f4076e4 = () => interopDefault(import('../pages/info/restricted-jurisdictions.vue' /* webpackChunkName: "pages/info/restricted-jurisdictions" */))
const _65133e17 = () => interopDefault(import('../pages/info/risk-disclosure.vue' /* webpackChunkName: "pages/info/risk-disclosure" */))
const _e8e24568 = () => interopDefault(import('../pages/info/user-agreement.vue' /* webpackChunkName: "pages/info/user-agreement" */))
const _90c78994 = () => interopDefault(import('../pages/info/withdrawal-policy.vue' /* webpackChunkName: "pages/info/withdrawal-policy" */))
const _d8b94cc0 = () => interopDefault(import('../pages/marketplace/add.vue' /* webpackChunkName: "pages/marketplace/add" */))
const _bc8a4ac0 = () => interopDefault(import('../pages/marketplace/all.vue' /* webpackChunkName: "pages/marketplace/all" */))
const _09bfd484 = () => interopDefault(import('../pages/marketplace/become-seller.vue' /* webpackChunkName: "pages/marketplace/become-seller" */))
const _e9492622 = () => interopDefault(import('../pages/marketplace/bestsellers.vue' /* webpackChunkName: "pages/marketplace/bestsellers" */))
const _00341b2f = () => interopDefault(import('../pages/marketplace/old__become-seller.vue' /* webpackChunkName: "pages/marketplace/old__become-seller" */))
const _d80396e2 = () => interopDefault(import('../pages/missions/best.vue' /* webpackChunkName: "pages/missions/best" */))
const _5972b498 = () => interopDefault(import('../pages/profile/personal-data.vue' /* webpackChunkName: "pages/profile/personal-data" */))
const _314709e4 = () => interopDefault(import('../pages/profile/verification.vue' /* webpackChunkName: "pages/profile/verification" */))
const _f98383e2 = () => interopDefault(import('../pages/steam/register.vue' /* webpackChunkName: "pages/steam/register" */))
const _da0dccca = () => interopDefault(import('../pages/store/cart.vue' /* webpackChunkName: "pages/store/cart" */))
const _0ee4817e = () => interopDefault(import('../pages/store/checkout.vue' /* webpackChunkName: "pages/store/checkout" */))
const _5806b8ec = () => interopDefault(import('../pages/store/gc-checkout.vue' /* webpackChunkName: "pages/store/gc-checkout" */))
const _83b6f256 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7f986c77 = () => interopDefault(import('../pages/marketplace/comics/_id/index.vue' /* webpackChunkName: "pages/marketplace/comics/_id/index" */))
const _4574a990 = () => interopDefault(import('../pages/marketplace/order/_id.vue' /* webpackChunkName: "pages/marketplace/order/_id" */))
const _2c20c1dc = () => interopDefault(import('../pages/marketplace/product/_id/index.vue' /* webpackChunkName: "pages/marketplace/product/_id/index" */))
const _82cd8b76 = () => interopDefault(import('../pages/offer/ad/_partner.vue' /* webpackChunkName: "pages/offer/ad/_partner" */))
const _1d3e7aa2 = () => interopDefault(import('../pages/user/id/_id.js' /* webpackChunkName: "pages/user/id/_id" */))
const _56061181 = () => interopDefault(import('../pages/marketplace/comics/_id/read.vue' /* webpackChunkName: "pages/marketplace/comics/_id/read" */))
const _3a07fc90 = () => interopDefault(import('../pages/marketplace/product/_id/payment.vue' /* webpackChunkName: "pages/marketplace/product/_id/payment" */))
const _54a91536 = () => interopDefault(import('../pages/marketplace/_app/index.vue' /* webpackChunkName: "pages/marketplace/_app/index" */))
const _14a9e54f = () => interopDefault(import('../pages/missions/_name.vue' /* webpackChunkName: "pages/missions/_name" */))
const _149f298a = () => interopDefault(import('../pages/offer/_id.vue' /* webpackChunkName: "pages/offer/_id" */))
const _739cf3c6 = () => interopDefault(import('../pages/p/_alias.vue' /* webpackChunkName: "pages/p/_alias" */))
const _0de88a5d = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _7aea7b5e = () => interopDefault(import('../pages/store/_tab.vue' /* webpackChunkName: "pages/store/_tab" */))
const _360fc8ce = () => interopDefault(import('../pages/t/_alias.vue' /* webpackChunkName: "pages/t/_alias" */))
const _50524773 = () => interopDefault(import('../pages/user/_user.vue' /* webpackChunkName: "pages/user/_user" */))
const _ce78517e = () => interopDefault(import('../pages/marketplace/_app/_category.vue' /* webpackChunkName: "pages/marketplace/_app/_category" */))
const _3a27fa52 = () => interopDefault(import('../pages/_game/index.vue' /* webpackChunkName: "pages/_game/index" */))
const _118d6afa = () => interopDefault(import('../pages/_game/duels/index.vue' /* webpackChunkName: "pages/_game/duels/index" */))
const _404b6798 = () => interopDefault(import('../pages/_game/phased-tournaments/index.vue' /* webpackChunkName: "pages/_game/phased-tournaments/index" */))
const _3ae96332 = () => interopDefault(import('../pages/_game/tournaments/index.vue' /* webpackChunkName: "pages/_game/tournaments/index" */))
const _6eada6aa = () => interopDefault(import('../pages/_game/tournaments/new/_id.vue' /* webpackChunkName: "pages/_game/tournaments/new/_id" */))
const _328e11ab = () => interopDefault(import('../pages/_game/duels/_id.vue' /* webpackChunkName: "pages/_game/duels/_id" */))
const _6635cb9c = () => interopDefault(import('../pages/_game/phased-tournaments/_id.vue' /* webpackChunkName: "pages/_game/phased-tournaments/_id" */))
const _010aebcc = () => interopDefault(import('../pages/_game/tournaments/_id.vue' /* webpackChunkName: "pages/_game/tournaments/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/become-advertiser",
    component: _32ad42f4,
    pathToRegexpOptions: {"strict":true},
    name: "become-advertiser___ru"
  }, {
    path: "/bonus",
    component: _442c0fa2,
    pathToRegexpOptions: {"strict":true},
    name: "bonus___ru"
  }, {
    path: "/earn",
    component: _580d0368,
    pathToRegexpOptions: {"strict":true},
    name: "earn___ru"
  }, {
    path: "/games",
    component: _7bad84c7,
    pathToRegexpOptions: {"strict":true},
    name: "games___ru"
  }, {
    path: "/leaderboard",
    component: _29b33bc0,
    pathToRegexpOptions: {"strict":true},
    name: "leaderboard___ru"
  }, {
    path: "/login",
    component: _f067ac28,
    pathToRegexpOptions: {"strict":true},
    name: "login___ru"
  }, {
    path: "/marketplace",
    component: _46f96b91,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace___ru"
  }, {
    path: "/missions",
    component: _716de6b7,
    pathToRegexpOptions: {"strict":true},
    name: "missions___ru"
  }, {
    path: "/notifications",
    component: _02d3462a,
    pathToRegexpOptions: {"strict":true},
    name: "notifications___ru"
  }, {
    path: "/privacy-policy",
    component: _2cb7d3b8,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___ru"
  }, {
    path: "/profile",
    component: _7ed39528,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ru",
    children: [{
      path: "account",
      component: _1093acec,
      pathToRegexpOptions: {"strict":true},
      name: "profile-account___ru"
    }, {
      path: "achievements",
      component: _3ed44f97,
      pathToRegexpOptions: {"strict":true},
      name: "profile-achievements___ru"
    }, {
      path: "balance",
      component: _b389c28e,
      pathToRegexpOptions: {"strict":true},
      name: "profile-balance___ru"
    }, {
      path: "events",
      component: _7386974c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-events___ru"
    }, {
      path: "favourites",
      component: _6be555c6,
      pathToRegexpOptions: {"strict":true},
      name: "profile-favourites___ru"
    }, {
      path: "game-accounts",
      component: _456a3e04,
      pathToRegexpOptions: {"strict":true},
      name: "profile-game-accounts___ru"
    }, {
      path: "goods",
      component: _0a898773,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods___ru"
    }, {
      path: "goods-moderation",
      component: _a75f3320,
      pathToRegexpOptions: {"strict":true},
      name: "profile-goods-moderation___ru"
    }, {
      path: "lichess-connect",
      component: _fd7e5cc6,
      pathToRegexpOptions: {"strict":true},
      name: "profile-lichess-connect___ru"
    }, {
      path: "notifications",
      component: _05f91705,
      pathToRegexpOptions: {"strict":true},
      name: "profile-notifications___ru"
    }, {
      path: "orders",
      component: _65f05318,
      pathToRegexpOptions: {"strict":true},
      name: "profile-orders___ru"
    }, {
      path: "quests",
      component: _5b329e04,
      pathToRegexpOptions: {"strict":true},
      name: "profile-quests___ru"
    }, {
      path: "ref",
      component: _20f5c120,
      pathToRegexpOptions: {"strict":true},
      name: "profile-ref___ru"
    }, {
      path: "sales",
      component: _9b1f23ae,
      pathToRegexpOptions: {"strict":true},
      name: "profile-sales___ru"
    }, {
      path: "security",
      component: _1e63b19a,
      pathToRegexpOptions: {"strict":true},
      name: "profile-security___ru"
    }, {
      path: "stats",
      component: _3bdae83c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-stats___ru"
    }, {
      path: "subscription",
      component: _bb60a5e0,
      pathToRegexpOptions: {"strict":true},
      name: "profile-subscription___ru"
    }]
  }, {
    path: "/reset",
    component: _66e6f4d2,
    pathToRegexpOptions: {"strict":true},
    name: "reset___ru"
  }, {
    path: "/store",
    component: _dc13ba32,
    pathToRegexpOptions: {"strict":true},
    name: "store___ru"
  }, {
    path: "/subscriptions",
    component: _b822480e,
    pathToRegexpOptions: {"strict":true},
    name: "subscriptions___ru"
  }, {
    path: "/terms_and_conditions",
    component: _ccad8ab6,
    pathToRegexpOptions: {"strict":true},
    name: "terms_and_conditions___ru"
  }, {
    path: "/tournaments",
    component: _3f2e4d26,
    pathToRegexpOptions: {"strict":true},
    name: "tournaments___ru"
  }, {
    path: "/user",
    component: _2aad779b,
    pathToRegexpOptions: {"strict":true},
    name: "user___ru"
  }, {
    path: "/verify",
    component: _c21d00f4,
    pathToRegexpOptions: {"strict":true},
    name: "verify___ru"
  }, {
    path: "/desktop/download",
    component: _63123e30,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-download___ru"
  }, {
    path: "/desktop/howto",
    component: _9bfa61ca,
    pathToRegexpOptions: {"strict":true},
    name: "desktop-howto___ru"
  }, {
    path: "/earn/referral",
    component: _04c8a412,
    pathToRegexpOptions: {"strict":true},
    name: "earn-referral___ru"
  }, {
    path: "/earn/stories",
    component: _06176a2e,
    pathToRegexpOptions: {"strict":true},
    name: "earn-stories___ru"
  }, {
    path: "/earn/verification",
    component: _7c66af16,
    pathToRegexpOptions: {"strict":true},
    name: "earn-verification___ru"
  }, {
    path: "/info/about",
    component: _150495f9,
    pathToRegexpOptions: {"strict":true},
    name: "info-about___ru"
  }, {
    path: "/info/age-restrictions",
    component: _49cfad79,
    pathToRegexpOptions: {"strict":true},
    name: "info-age-restrictions___ru"
  }, {
    path: "/info/contacts",
    component: _3171d297,
    pathToRegexpOptions: {"strict":true},
    name: "info-contacts___ru"
  }, {
    path: "/info/cookie-policy",
    component: _4bede427,
    pathToRegexpOptions: {"strict":true},
    name: "info-cookie-policy___ru"
  }, {
    path: "/info/g-cash",
    component: _d82641c6,
    pathToRegexpOptions: {"strict":true},
    name: "info-g-cash___ru"
  }, {
    path: "/info/kyc-aml",
    component: _061956d8,
    pathToRegexpOptions: {"strict":true},
    name: "info-kyc-aml___ru"
  }, {
    path: "/info/restricted-jurisdictions",
    component: _1f4076e4,
    pathToRegexpOptions: {"strict":true},
    name: "info-restricted-jurisdictions___ru"
  }, {
    path: "/info/risk-disclosure",
    component: _65133e17,
    pathToRegexpOptions: {"strict":true},
    name: "info-risk-disclosure___ru"
  }, {
    path: "/info/user-agreement",
    component: _e8e24568,
    pathToRegexpOptions: {"strict":true},
    name: "info-user-agreement___ru"
  }, {
    path: "/info/withdrawal-policy",
    component: _90c78994,
    pathToRegexpOptions: {"strict":true},
    name: "info-withdrawal-policy___ru"
  }, {
    path: "/marketplace/add",
    component: _d8b94cc0,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-add___ru"
  }, {
    path: "/marketplace/all",
    component: _bc8a4ac0,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-all___ru"
  }, {
    path: "/marketplace/become-seller",
    component: _09bfd484,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-become-seller___ru"
  }, {
    path: "/marketplace/bestsellers",
    component: _e9492622,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-bestsellers___ru"
  }, {
    path: "/marketplace/old__become-seller",
    component: _00341b2f,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-old__become-seller___ru"
  }, {
    path: "/missions/best",
    component: _d80396e2,
    pathToRegexpOptions: {"strict":true},
    name: "missions-best___ru"
  }, {
    path: "/profile/personal-data",
    component: _5972b498,
    pathToRegexpOptions: {"strict":true},
    name: "profile-personal-data___ru"
  }, {
    path: "/profile/verification",
    component: _314709e4,
    pathToRegexpOptions: {"strict":true},
    name: "profile-verification___ru"
  }, {
    path: "/steam/register",
    component: _f98383e2,
    pathToRegexpOptions: {"strict":true},
    name: "steam-register___ru"
  }, {
    path: "/store/cart",
    component: _da0dccca,
    pathToRegexpOptions: {"strict":true},
    name: "store-cart___ru"
  }, {
    path: "/store/checkout",
    component: _0ee4817e,
    pathToRegexpOptions: {"strict":true},
    name: "store-checkout___ru"
  }, {
    path: "/store/gc-checkout",
    component: _5806b8ec,
    pathToRegexpOptions: {"strict":true},
    name: "store-gc-checkout___ru"
  }, {
    path: "/",
    component: _83b6f256,
    pathToRegexpOptions: {"strict":true},
    name: "index___ru"
  }, {
    path: "/marketplace/comics/:id",
    component: _7f986c77,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-comics-id___ru"
  }, {
    path: "/marketplace/order/:id?",
    component: _4574a990,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-order-id___ru"
  }, {
    path: "/marketplace/product/:id",
    component: _2c20c1dc,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id___ru"
  }, {
    path: "/offer/ad/:partner?",
    component: _82cd8b76,
    pathToRegexpOptions: {"strict":true},
    name: "offer-ad-partner___ru"
  }, {
    path: "/user/id/:id?",
    component: _1d3e7aa2,
    pathToRegexpOptions: {"strict":true},
    name: "user-id-id___ru"
  }, {
    path: "/marketplace/comics/:id?/read",
    component: _56061181,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-comics-id-read___ru"
  }, {
    path: "/marketplace/product/:id?/payment",
    component: _3a07fc90,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-product-id-payment___ru"
  }, {
    path: "/marketplace/:app",
    component: _54a91536,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app___ru"
  }, {
    path: "/missions/:name",
    component: _14a9e54f,
    pathToRegexpOptions: {"strict":true},
    name: "missions-name___ru"
  }, {
    path: "/offer/:id?",
    component: _149f298a,
    pathToRegexpOptions: {"strict":true},
    name: "offer-id___ru"
  }, {
    path: "/p/:alias?",
    component: _739cf3c6,
    pathToRegexpOptions: {"strict":true},
    name: "p-alias___ru"
  }, {
    path: "/product/:id?",
    component: _0de88a5d,
    pathToRegexpOptions: {"strict":true},
    name: "product-id___ru"
  }, {
    path: "/store/:tab",
    component: _7aea7b5e,
    pathToRegexpOptions: {"strict":true},
    name: "store-tab___ru"
  }, {
    path: "/t/:alias?",
    component: _360fc8ce,
    pathToRegexpOptions: {"strict":true},
    name: "t-alias___ru"
  }, {
    path: "/user/:user",
    component: _50524773,
    pathToRegexpOptions: {"strict":true},
    name: "user-user___ru"
  }, {
    path: "/marketplace/:app/:category",
    component: _ce78517e,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-app-category___ru"
  }, {
    path: "/:game",
    component: _3a27fa52,
    pathToRegexpOptions: {"strict":true},
    name: "game___ru"
  }, {
    path: "/:game/duels",
    component: _118d6afa,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels___ru"
  }, {
    path: "/:game/phased-tournaments",
    component: _404b6798,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments___ru"
  }, {
    path: "/:game/tournaments",
    component: _3ae96332,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments___ru"
  }, {
    path: "/:game/tournaments/new/:id?",
    component: _6eada6aa,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-new-id___ru"
  }, {
    path: "/:game/duels/:id",
    component: _328e11ab,
    pathToRegexpOptions: {"strict":true},
    name: "game-duels-id___ru"
  }, {
    path: "/:game/phased-tournaments/:id?",
    component: _6635cb9c,
    pathToRegexpOptions: {"strict":true},
    name: "game-phased-tournaments-id___ru"
  }, {
    path: "/:game/tournaments/:id",
    component: _010aebcc,
    pathToRegexpOptions: {"strict":true},
    name: "game-tournaments-id___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
